import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from 'react'
import { Helmet } from "react-helmet"
import Accordion from '../components/Accordion'
import CallOut from '../components/CallOut'
import Card from '../components/Card'
import CardContainer from '../components/CardContainer'
import Carousel from '../components/Carousel'
import ClinicFinder from '../components/ClinicFinder'
import CMSModal from '../components/CMSModal'
import DetailTemplate from '../components/DetailTemplate'
import FactBox from '../components/FactBox'
import FlipCard from '../components/FlipCard'
import FlipCardContainer from '../components/FlipCardContainer'
import HeaderWithImage from '../components/HeaderWithImage'
import HTTTYP from '../components/HTTTYP'
import LinkButton from '../components/LinkButton'
import Question from '../components/Question'
import Quiz from '../components/Quiz'
import ShowMore from '../components/ShowMore'
import Slide from '../components/Slide'
import TextCarousel from '../components/TextCarousel'
import TextSlide from '../components/TextSlide'

function Detail({pageContext}) {
  const shortcodes = { 
    Accordion, FactBox, Carousel, Slide, Card, CardContainer, 
    ShowMore, HeaderWithImage, FlipCard, TextCarousel, TextSlide, Question,
    Quiz, ClinicFinder, LinkButton, HTTTYP, FlipCardContainer, CMSModal,
    CallOut
  }
  const { detail } = pageContext

  return (
    <div className="Detail">
      <Helmet>
        <title>{detail.frontmatter.detailTitle}</title>
        <meta name="description" content={detail.frontmatter.detailTitle} />
      </Helmet>
      <DetailTemplate
        detailTitle={detail.frontmatter.detailTitle}
        topicTitle={detail.frontmatter.topicTitle}
        sectionTitle={detail.frontmatter.sectionTitle}
        path={detail.slug}
        image={detail.frontmatter.image}>
        <MDXProvider components={shortcodes}>
          <MDXRenderer>
            {detail.body}
          </MDXRenderer>
        </MDXProvider>
      </DetailTemplate>
    </div>
  )
}

export default Detail;